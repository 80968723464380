.ms-Dialog-main{
    width: 50%;
    left:25%;
}
.ms-Modal-scrollableContent{
    padding: 1rem 0 0 5rem;
}
.send-otp-button{
    font-size: 10px;
    width: 4rem;
    padding: 0 5px;
    height: 20px;
    margin: 0.6rem 0 0 1rem;
}
.verify-config-button{
    margin: 2rem 0;
}