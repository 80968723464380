ul.ui-chat{
    border-left-style: none;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0.625rem;
    padding-bottom: 0px;
    padding-left: 0.625rem;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    height: 100%;
    overflow: auto;
}

li.ui-chat__item{
  padding-top: 1rem;
  position: relative;
  padding-bottom: 0px;
}

.ui-chat__item__message{
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    position: relative;
}

.ui-flex{
    align-items: flex-end;
     display: flex;
}

.ui-chat__message{
    overflow-wrap: break-word;
    word-break: break-word;
    color: rgb(64, 64, 64);
    outline-width: 0px;
    border-radius: 0.1875rem;
    padding-bottom: 0.625rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    min-width: 8rem;
    max-width: calc(100% - 6.25rem);
    position: relative;
    margin-right: 0px;
    border-left-style: none;
    border-bottom-style: none;
    border-right-style: none;
    border-top-style: none;
    display: inline-block;
}

.ui-chat__message__author{
    font-weight: 400;
    margin-bottom: 0.125rem;
    margin-right: 0.75rem;
    color: rgb(72, 70, 68);
    line-height: 1.3333;
    font-size: 0.75rem;
}
.ui-chat__message__content{
    display: block;
   color: rgb(37, 36, 35);
}
.ui-chat__item__gutter{
   left: 0px;
   margin-top: 0.625rem;
   position: absolute;
}
.chat-flex-right{
    float: right;
}
.donate-button{
    color: #fff;
    background-color: #D74654;
    border: #D74654;
    width: auto;
    height: 2rem;
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.donate_container{
    width: 40%;
    display:block;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    top: 11%;
    right: 4%;
    bottom: 0;
    left: 0; 
    z-index:2147483647;
    -webkit-overflow-scrolling: touch;
    max-height:none;
  }
  .donate_iframe{
    width:100%; 
    height:100%; 
    position:fixed;
    background:transparent !important;
    /* margin-top: 70px; */
  }
  .donate_close{
    color:#eee;
    font-family:verdana;
    border-radius:3px;
    background:#333;
    padding:10px 20px 10px 10px;
    margin-left: 75%;
    /* top:75px; */
    position:relative;
    border:1px solid #000;
    font-weight:400; 
    font-size:18px !important;
    cursor: pointer;
  }
  .donate_head{
    width: 100%;
    text-align: left;
    position: absolute;
    z-index: 99999;
    height: 10rem;
    cursor: move;
  }
  .extrausers{
    background-color: #7a7a7a;
    opacity: 0.7;
  }
  .users-count{
    color: #fff;
    font-size: large;
    font-weight: bold;
    position: absolute;
  }
  .ui-chat__message__timestamp{
    font-size: 10px;
 }

 #video-gallery > div{
   min-height: 9.375rem;
 }
 .ms-Image-image--contain{
  display: block;
  max-height: 10rem;
  max-width: 10rem;
  opacity: 1;
  width: 100% !important;
  height: auto;
  object-fit: fill !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
 @media only screen and (max-width: 768px){
  .header-logo{
    display: none !important;
  }
  .css-334{
    display: block !important;
  }
  .chat-container{
    width: 100% !important;
  }
  ul.ui-chat{
    min-height: 6.5rem !important;
  }
 }
/* .body-335{
 width: 50%;
} */

/* .wrapper {
  height: 100%;
  width: 100%;
  display: block !important;
  align-items: normal !important;
  justify-content: normal !important;
}
.css-334 {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
 
} */
/* 
.ui-dialog__overlay{

  overflow: auto;
  background: rgba(37, 36, 36, 0.75);
  right: 0px;
  bottom: 0px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  z-index: 1000;
  left: 0px;
  top: 0px;
  position: fixed;
  
  }
  .ui-dialog{
  
  color: rgb(15, 15, 15);
  box-shadow: rgb(0 0 0 / 10%) 0px 1.6rem 1.8rem -0.4rem;
  grid-template-columns: 1fr auto;
  max-height: 100vh;
  max-width: 100vw;
  width: 600px;
  padding-left: 2.2857rem;
  padding-bottom: 2.2857rem;
  padding-top: 1.9286rem;
  border-radius: 8px;
  display: grid;
  border-left-style: none;
  border-bottom-style: none;
  border-right-style: none;
  border-top-style: none;
  padding-right: 2.2857rem;
  overflow-x: hidden;
  overflow-y: auto;
  outline-style: none;
  position: relative;
  background: rgb(255, 255, 255);
  
  }
  
  .ui-dialog__header{
  
  justify-self: left;
  grid-row: 1 / auto;
  margin-bottom: 0.5714rem;
  grid-column: 1 / auto;
  font-size: 1.2857rem;
  font-weight: 700;
  color: rgb(36, 36, 36);
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  display: block;
  }
  
  .ui-dialog__content{
      overflow: unset;
      max-height: 100%;
      grid-row: 2 / auto;
      grid-column: 1 / span 2;
      margin-bottom: 1.4286rem;
      justify-self: left;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      width: 100%;
  }
  
  .ui-dialog__footer{
      grid-row: 3 / auto;
      grid-column: 1 / -1;
      text-align: right;
  }
  
  
  .ui-dialog__footer .ui-buttons{
      width: 100%;
      display: flex;
  }
  
  .ui-dialog__footer .ui-buttons .ui-flex{
      width: 100%;
      flex-wrap: wrap;
      -webkit-box-pack: end;
      justify-content: flex-end;
      display: flex;
  }
  
  .ui-dialog__footer .ui-buttons .ui-flex .ui-button{
  
      margin-right: 0.5714rem;
      border-bottom-color: rgb(209, 209, 209);
      border-right-color: rgb(209, 209, 209);
      border-top-color: rgb(209, 209, 209);
      max-width: 20rem;
      box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
      border-left-color: rgb(209, 209, 209);
      background-color: rgb(255, 255, 255);
      border-left-width: 1px;
      border-bottom-width: 1px;
      border-right-width: 1px;
      border-top-width: 1px;
      transition: all 100ms ease 0s;
      color: rgb(36, 36, 36);
      min-width: 6.8571rem;
      height: 2.2857rem;
      user-select: none;
      padding-left: 1.4286rem;
      padding-right: 1.4286rem;
      border-radius: 4px;
      outline-width: 0px;
      position: relative;
      display: inline-flex;
      -webkit-box-pack: center;
      justify-content: center;
      cursor: pointer;
      vertical-align: middle;
      -webkit-box-align: center;
      align-items: center;
      padding-bottom: 0px;
      padding-top: 0px;
      border-left-style: solid;
      border-bottom-style: solid;
      border-right-style: solid;
      border-top-style: solid;
  }
  
  .ui-button__content{
      line-height: 1.4286;
      font-size: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 600;
  } */